import {initSlider} from "@otto-ec/nav_patternsson/src/js/slider";
import {navigateUp} from "./navigation";
import * as naviElemDom from "./dom/naviElem";
import {hasLevelContainer, SELECTORS} from "./dom/naviElem";
import {getMenuRoot} from "./dom/menu";
import {defaultErrorHandler, fetchHtml, findParentNodeByAttribute, addMenuShortcutLabels} from "./utils";
import * as levelContainerDom from "./dom/levelContainer";
import {isLevel2Container} from "./dom/levelContainer";
import * as naviPanelDom from "./dom/naviPanel";
import {isInLevel2} from "./dom/naviPanel";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

function getLogger(namespace) {
    if (window.o_global &&
        window.o_global.debug &&
        window.o_global.debug.logger &&
        typeof window.o_global.debug.logger === 'function') {
        return window.o_global.debug.logger(namespace);
    } else {
        return {
            trace: () => {},
            debug: () => {},
            info: () => {},
            warn: () => {},
            error: () => {}
        };
    }
}

const log = getLogger("ft-nav.chekov");

let navigationPath = [];
let lvl1ClickedRank = null;

export function popLvl1ClickedRank() {
    const rank = JSON.parse(JSON.stringify(lvl1ClickedRank));
    lvl1ClickedRank = null;
    return rank;
}

export function getLvl1ItemIndex() {
    const menuRoot = getMenuRoot();
    return Array.from(menuRoot.querySelectorAll('.nav_navi-elem--level-1'));
}

function navigateDown(naviElem, levelContainer, navigationPath, menuState) {
    const naviPanel = findParentNodeByAttribute(naviElem, 'data-nav-swipe-status');

    navigationPath.push(naviPanel);
    naviPanelDom.animateDown(naviPanel);
    levelContainerDom.makeVisible(levelContainer);

    track(naviElem, "user-action/menu-navigate-down", (commands) => {
        if (isLevel2Container(levelContainer)) {
            const lvl1Blocks = getLvl1ItemIndex();
            if (lvl1Blocks.length > 0) {
                const correctRank = lvl1Blocks.indexOf(naviElem) + 1;
                lvl1ClickedRank = correctRank;
                commands[0].dataContainer.nav_MenuRank = correctRank;
            }
        }
        if(menuState?.shortcutMenu !== undefined) {
            commands[0].dataContainer["nav_FirstLevelPosition"] = menuState.shortcutMenu.position;
            commands[0].dataContainer["nav_PersonalisedCategories"] = menuState.shortcutMenu.isPersonalised;
            commands[0].dataContainer["nav_GlobalNavigationFeature"] = "top_category";
        } else {
            commands[0].dataContainer["nav_GlobalNavigationFeature"] = "standard";
        }
        return commands;
    });
}

function instantlyShowNextLevel(naviElem, navigationPath) {
    const levelContainer = naviElem.parentNode.querySelector(levelContainerDom.SELECTORS.BLOCK);
    if (!!levelContainer) {
        const naviPanel = findParentNodeByAttribute(naviElem, 'data-nav-swipe-status');

        navigationPath.push(naviPanel);
        naviPanelDom.animateDown(naviPanel);
        levelContainerDom.makeVisible(levelContainer);
    }
}

function navigateIntoLayer(naviElem, navigationPath, whenVisible = () => {
}) {
    if (hasLevelContainer(naviElem)) {
        instantlyShowNextLevel(naviElem, navigationPath);
        whenVisible();
        /*                                                                     */
        /*                                                                     */
        /*                                                                     */
        /*                                                                    */
        /*                                              */
        return Promise.resolve();
    } else {
        const downLink = naviElemDom.getDownLink(naviElem);
        if (!!downLink) {
            /*                */
            return fetchHtml(downLink)
                .then(result => {
                    if (!!result && result.status < 400 && !!result.response) {
                        const levelContainer = result.response.querySelector(levelContainerDom.SELECTORS.BLOCK);
                        if (!!levelContainer) {
                            naviElemDom.appendLevelContainer(naviElem, levelContainer);
                            instantlyShowNextLevel(naviElem, navigationPath);
                            window.o_util.hardcore.executeInlineScripts(levelContainer);
                            whenVisible();
                        }
                    }
                })
                .catch(defaultErrorHandler);
        }
        return Promise.resolve();
    }
}

function rearrangeElements() {
    const naviPanel = document.querySelector('.nav_navi-panel__body');
    if (!naviPanel) {
        return;
    }
    const naviList = document.querySelector('.nav_navi-list.nav_navi-list--level-1');
    if (!naviList) {
        return;
    }

    const naviList1 = document.createElement('ul');
    const naviList2 = document.createElement('ul');
    const newClasslist = Array.from(naviList.classList.values());

    naviList1.classList.add(...newClasslist);
    naviList2.classList.add(...newClasslist);
    naviPanel.insertBefore(naviList1, naviList);
    naviPanel.insertBefore(naviList2, naviList);

    const listElems = Array.from(naviList.children)
        .filter((elem) => !elem.classList.contains('nav_navi-list__separator'));
    Array.from(naviList.children).forEach((elem) => {
        if (elem.classList.contains('nav_navi-list__separator')) {
            elem.remove();
        }
    });

    listElems.forEach((listElem) => {
        const elem = listElem.querySelector('.nav_navi-elem.nav_navi-elem--level-1');
        if (!elem) {
            return;
        }
        const blockId = elem.dataset.mobileBlock;

        if (parseInt(blockId, 10) === 1) {
            naviList1.insertBefore(listElem, null);
        } else if (parseInt(blockId, 10) === 2) {
            naviList2.insertBefore(listElem, null);
        }
    });
}

function downloadLevelContainer(downLink) {
    return fetchHtml(downLink)
        .then(result => {
            if (!!result && result.status < 400 && !!result.response) {
                return result.response.querySelector(levelContainerDom.SELECTORS.BLOCK);
            }
        });
}

export function initMobile(menuState) {
    const menuRoot = getMenuRoot();

    if (!menuRoot) {
        return;
    }

    rearrangeElements();

    /*                                                                            */
    /*                                                                              */
    /*                                                                    */
    /*                                                                         */
    /*                                                                               */
    /*                                                                     */
    /*                                                                                            */
    /*                                                                     */
    /*                                                                                */
    menuRoot.addEventListener('click', event => {
        const naviElem = event.target.closest(SELECTORS.BLOCK);
        const downLink = naviElemDom.getDownLink(naviElem);

        let {menuAction, menuActionEl} = naviElemDom.getMenuAction(event.target);

        if (downLink) {
            menuAction = 'down';
        }

        switch (menuAction) {
            case 'back':
            navigateUp(menuActionEl, navigationPath, menuState);
                event.stopPropagation();
                break;
            case 'down':
                if (naviElemDom.isLevel1(naviElem)) {
                    event.preventDefault();
                }
                if (!hasLevelContainer(naviElem)) {
                    downloadLevelContainer(downLink)
                        .then(levelContainer => {
                            if (!!levelContainer) {
                                naviElemDom.appendLevelContainer(naviElem, levelContainer);
                                navigateDown(naviElem, levelContainer, navigationPath, menuState);
                                window.o_util.hardcore.executeInlineScripts(levelContainer);
                            }
                        })
                        .catch(defaultErrorHandler);
                } else {
                    let levelContainer = naviElem.parentNode.querySelector(levelContainerDom.SELECTORS.BLOCK);
                    navigateDown(naviElem, levelContainer, navigationPath, menuState);
                }
            break;
            /*       */
        default:
            track(event.target, "user-action/visit-menu-link", (commands) => {
                const trackedElement = event.target.closest('.nav-track');
                if (trackedElement && isInLevel2(trackedElement)) {
                    commands[0].dataContainer["wk.nav_MenuRank"] = lvl1ClickedRank;
                }
                addMenuShortcutLabels(commands, menuState);
                if (menuState?.shortcutMenu?.isPersonalised !== undefined) {
                    commands[0].dataContainer["wk.nav_GlobalNavigationFeature"] = "top_category";
                } else {
                    commands[0].dataContainer["wk.nav_GlobalNavigationFeature"] = "standard";
                }
                return commands;
                });
        }
    });

    if (!!window.o_global) {
        window.o_global.eventQBus.on('ftnav.global-navigation.open-layer', (downLink) => {
            const naviElem = Array.from(menuRoot.querySelectorAll('[data-nav-down-link]'))
                .filter(elem => elem.dataset.navDownLink === downLink)[0];
            navigateIntoLayer(naviElem, navigationPath).then(

                () => window.o_global.eventQBus.emit('ftnav.global-navigation.openSilent')
            );
        });
    }
}

export function clearNavigationPath() {
    navigationPath = [];
}
