import * as naviPanelDom from "./dom/naviPanel";
import * as levelContainerDom from "./dom/levelContainer";

export function isMobileBreakpoint() {
    const currentBreakpoint = getCurrentBreakpoint();
    return currentBreakpoint === "s" || currentBreakpoint === "m"
}

export function isTouchDevice() {
    return window.o_global && window.o_global.device.isTouchable;
}

function getCurrentBreakpoint() {
    return o_global.breakpoint.getCurrentBreakpoint();
}

/**
 *
 *
 *
 */
export function merge(target, params) {
    let result = target;
    for (const pKey in params) {
        result[pKey] = params[pKey];
    }

    return result;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function findParentNodeByAttribute(startNode, attribute, maxSteps = 4, currentStep = 0) {
    let swipeData = startNode.getAttribute(attribute);
    if (!!swipeData || maxSteps === currentStep)
        return startNode;

    return findParentNodeByAttribute(startNode.parentNode, attribute, maxSteps, currentStep + 1);
}

export function resetClasses() {
    /*                                                                                                           */
    /*                                                                                               */
    /*                                    */
    /*                                                                    */
    let naviPanels = document.querySelectorAll(naviPanelDom.SELECTORS.DOWN_ANIMATION_MODIFIER);
    let visibleLevelContainers = document.querySelectorAll(levelContainerDom.SELECTORS.VISIBLE_MODIFIER);

    if (naviPanels.length > 0) {
        for (let i = 0; i < naviPanels.length; i++) {
            naviPanelDom.animateUp(naviPanels[i]);
        }
    }

    if (visibleLevelContainers.length > 0) {
        for (let i = 0; i < visibleLevelContainers.length; i++) {
            levelContainerDom.makeInvisible(visibleLevelContainers[i]);
        }
    }
}

/*                                                              */
export function fetchHtml(url) {
    return window.o_util.ajax({
        method: 'GET',
        url: url,
        contentType: 'text/html;charset=UTF-8',
        responseType: 'document'
    });
}

export function defaultErrorHandler(error) {
    if (o_global.debug.status().activated) {
        console.log(error);
    }
}

export function calculateTopOffset(element) {
    const topOffset = element.getBoundingClientRect().top + window.scrollX;
    return `${topOffset}px`;
}

export function addMenuShortcutLabels(commands, menuState) {
    if (menuState?.shortcutMenu?.isPersonalised !== undefined) {
        commands[0].dataContainer["wk.nav_PersonalisedCategories"] = menuState.shortcutMenu.isPersonalised;
    }

    if (menuState?.shortcutMenu?.position) {
        commands[0].dataContainer["wk.nav_FirstLevelPosition"] = menuState.shortcutMenu.position;
    }

    return commands;
}