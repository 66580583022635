/*                                                                              */

const log = getLogger("ft-nav.patternsson.assetsHelpers");

export function getLogger(namespace) {
    if (window.o_global &&
      window.o_global.debug &&
      window.o_global.debug.logger &&
      typeof window.o_global.debug.logger === 'function') {
        return window.o_global.debug.logger(namespace);
    } else {
        return {
            trace: () => {},
            debug: () => {},
            info: () => {},
            warn: () => {},
            error: () => {}
        }
    }
}

export function eventQBusEmit(topic, ...data) {
    if (window.o_global &&
        window.o_global.eventQBus &&
        window.o_global.eventQBus.emit &&
        typeof window.o_global.eventQBus.emit === 'function') {
        window.o_global.eventQBus.emit(topic, ...data);
    } else {
        log.warn("window.o_global.eventQBus.emit is not available");
    }
}
