import {isMobileBreakpoint} from "./utils";
import {getLogger} from "./assetsHelpers";

const log = getLogger("ft-nav.patternsson.slider");

const CSS_CLASSES = {
    'SLIDER': 'nav_slider',
    'SLIDER_WITH_BUTTONS_MODIFIER': 'nav_slider--with-buttons',
    'WINDOW': 'nav_slider__window',
    'BUTTON_LEFT': 'nav_slider__button-left',
    'BUTTON_RIGHT': 'nav_slider__button-right',
    'BUTTON_MOBILE': 'nav_slider__button-mobile',
    'BUTTON_LEFT_VISIBLE_MODIFIER': 'nav_slider__button-left--visible',
    'BUTTON_RIGHT_VISIBLE_MODIFIER': 'nav_slider__button-right--visible',
    'BUTTON_MOBILE_VISIBLE_MODIFIER': 'nav_slider__button-mobile--visible',
    'BUTTON_AIRBAG': 'nav_slider__button-airbag'
};

const SELECTORS = {
    'WINDOW': '.' + CSS_CLASSES.WINDOW,
    'BUTTON_LEFT': '.' + CSS_CLASSES.BUTTON_LEFT,
    'BUTTON_RIGHT': '.' + CSS_CLASSES.BUTTON_RIGHT,
    'BUTTON_MOBILE': '.' + CSS_CLASSES.BUTTON_MOBILE,
};

const debounced = (ms, f) => {
    let debounce = 0;
    return (...args) => {
        if (debounce === 0) {
            f(...args);
        }
        debounce += 1;
        setTimeout(() => {
            debounce -= 1;
        }, ms);
    };
};

class Slider {
    constructor(element) {
        this.element = element;
        this.windowElement = element.querySelector(SELECTORS.WINDOW);
        this.buttonLeft = element.querySelector(SELECTORS.BUTTON_LEFT);
        this.buttonRight = element.querySelector(SELECTORS.BUTTON_RIGHT);
        this.buttonMobile = element.querySelector(SELECTORS.BUTTON_MOBILE);

        this.scrollCallbacks = [];

        this.windowElement.addEventListener('scroll', debounced(1000, this.emitScrollEvent.bind(this)), {passive: true});

        this.element.classList.add(CSS_CLASSES.SLIDER_WITH_BUTTONS_MODIFIER);
        this.updateButtonVisibility();
        this.initializeMobileButtonVisibility();
        this.buttonLeft.addEventListener('click', this.scrollLeft.bind(this));
        this.buttonRight.addEventListener('click', this.scrollRight.bind(this));
        if (this.buttonMobile) {
            this.buttonMobile.addEventListener('click', this.scrollRightOnce.bind(this));
        }
        this.windowElement.addEventListener(
            'scroll',
            () => window.requestAnimationFrame(() => {
                    this.hideMobileButton();
                    this.updateButtonVisibility();
                }
            ),
            {passive: true},
        );
        /*                              */
        if (this.windowWidth() === 0) {
            let retryCounter = 0;
            const intervalId = setInterval(() => {
                if (this.windowWidth() !== 0) {
                    this.updateButtonVisibility();
                    this.initializeMobileButtonVisibility();
                    clearInterval(intervalId);
                }
                retryCounter++;
                if (retryCounter === 10) {
                    clearInterval(intervalId);
                }
            }, 200);
        }
    }

    windowWidth() {
        return this.windowElement.offsetWidth;
    }

    contentWidth() {
        return this.windowElement.scrollWidth;
    }

    maxScrollLeft() {
        return this.contentWidth() - this.windowWidth();
    }

    scrollWidth() {
        return this.windowWidth() / 3;
    }

    scrollLeft(evt) {
        this.windowElement.scrollBy({
            left: -this.scrollWidth(),
            behavior: 'smooth'
        });
    }

    scrollRight(evt) {
        this.windowElement.scrollBy({
            left: this.scrollWidth(),
            behavior: 'smooth'
        });
    }

    scrollRightOnce(evt) {
        this.windowElement.scrollBy({
            left: this.scrollWidth() * 2,
            behavior: 'smooth'
        });
        this.hideMobileButton();
    }

    updateButtonVisibility() {
        if (this.windowWidth() === 0) {
            this.hideLeftButton();
            this.showRightButton();
        } else if (this.contentWidth() > this.windowWidth()) {
            let scrollLeft = this.windowElement.scrollLeft;
            let epsilon = 3;

            if (scrollLeft < epsilon) {
                this.hideLeftButton();
                this.showRightButton();
            } else if (scrollLeft >= this.maxScrollLeft() - epsilon) {
                this.showLeftButton();
                this.hideRightButton();
            } else {
                this.showLeftButton();
                this.showRightButton();
            }
        } else {
            this.hideLeftButton();
            this.hideRightButton();
            log.info("updateButtonVisibility", this.contentWidth(), this.windowWidth());
        }
    }

    initializeMobileButtonVisibility() {
        if (this.windowWidth() === 0) {
            this.showMobileButton();
        } else if (this.contentWidth() > this.windowWidth()) {
            let scrollLeft = this.windowElement.scrollLeft;
            let epsilon = 3;

            if (scrollLeft < epsilon) {
                this.showMobileButton();
            }
        } else {
            this.hideMobileButton();
        }
    }

    hideLeftButton() {
        if (!this.buttonLeft.classList.contains(CSS_CLASSES.BUTTON_LEFT_VISIBLE_MODIFIER)) {
            return;
        }
        this.buttonLeft.classList.add(CSS_CLASSES.BUTTON_AIRBAG);
        this.buttonLeft.classList.remove(CSS_CLASSES.BUTTON_LEFT_VISIBLE_MODIFIER);
        setTimeout(() => {
            this.buttonLeft.classList.remove(CSS_CLASSES.BUTTON_AIRBAG);
        }, 500);
    }

    hideRightButton() {
        if (!this.buttonRight.classList.contains(CSS_CLASSES.BUTTON_RIGHT_VISIBLE_MODIFIER)) {
            return;
        }
        this.buttonRight.classList.add(CSS_CLASSES.BUTTON_AIRBAG);
        this.buttonRight.classList.remove(CSS_CLASSES.BUTTON_RIGHT_VISIBLE_MODIFIER);
        setTimeout(() => {
            this.buttonRight.classList.remove(CSS_CLASSES.BUTTON_AIRBAG);
        }, 500);
    }

    showLeftButton() {
        this.buttonLeft.classList.add(CSS_CLASSES.BUTTON_LEFT_VISIBLE_MODIFIER);
    }

    showRightButton() {
        this.buttonRight.classList.add(CSS_CLASSES.BUTTON_RIGHT_VISIBLE_MODIFIER);
    }

    showMobileButton() {
        const mobileButton = this.buttonMobile;
        if (mobileButton) {
            mobileButton.classList.add(CSS_CLASSES.BUTTON_MOBILE_VISIBLE_MODIFIER);
        }
    }

    hideMobileButton() {
        const mobileButton = this.buttonMobile;
        if (mobileButton) {
            mobileButton.classList.remove(CSS_CLASSES.BUTTON_MOBILE_VISIBLE_MODIFIER);
        }
    }

    emitScrollEvent(e) {
        this.scrollCallbacks.forEach((f) => {
            f(e);
        });
    }

    registerScrollCallback(f) {
        this.scrollCallbacks.push(f);
        return f;
    }
}

export function initSlider(sliderElement) {
    return new Slider(sliderElement);
}
