import {observeItem, observeMousemove} from "./mouseSpeedTracker";
import {navigateUp} from "./navigation";
import * as naviElemDom from './dom/naviElem';
import {hasLevelContainer, SELECTORS} from './dom/naviElem';
import * as naviPanelDom from './dom/naviPanel';
import * as levelContainerDom from './dom/levelContainer';
import * as menuDom from './dom/menu';
import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import {
    addMenuShortcutLabels,
    defaultErrorHandler,
    fetchHtml,
    findParentNodeByAttribute,
    isTouchDevice,
    resetClasses
} from "./utils";
import {
    FLYOUT_TRACKING_EVENTS,
    trackFlyoutCloseImmediately,
    trackFlyoutDebounced,
    trackFlyoutDown,
    trackFlyoutExitImmediately
} from "./tracking";
import {initSlider} from '@otto-ec/nav_patternsson/src/js/slider';

let navigationPath = [];

export function init(menuState) {
    observeMousemove();

    if (isTouchDevice()) {
        const navMenuRoot = menuDom.getMenuRoot();
        navMenuRoot.addEventListener('click', event => {
            const naviElem = event.target.closest(SELECTORS.BLOCK);
            const tabElem = event.target.closest(SELECTORS.TAB_ELEMENT);
            if (naviElem && !tabElem && naviElemDom.isLevel1(naviElem)) {
                event.preventDefault();

                /*          */
                onLeaveFirstLevel(navMenuRoot);
                onOverFirstLevel(naviElem.closest('li'));
            } else {
                track(event.target, "user-action/visit-menu-link", commands => addMenuShortcutLabels(commands, menuState));
            }
        });

        document.addEventListener('click', event => {
            if (!!event.target.closest(menuDom.SELECTORS.ID)) {
                return;
            }

            onLeaveFirstLevel(navMenuRoot);
        });
    } else {
        let wrapper = document.querySelector('.nav_navi-list');
        observeItem(
            wrapper,
            onOverFirstLevel,
            (menuWrapper) => {
                onLeaveFirstLevel(menuWrapper);
            }, {
                LIVE_SELECTOR: '.nav_navi-list--level-1 > li',
                SPEED_LIMIT: 0,
                IDLE: 150,
                FALLBACK: 500,
                CHECK_FROM_ELEMENT: true
            });
        const navMenuRoot = menuDom.getMenuRoot();
        navMenuRoot.addEventListener('click', event => {
            track(event.target, "user-action/visit-menu-link", commands => addMenuShortcutLabels(commands, menuState));
        });
    }

    /*                                                                                 */
    /*                   */
    document.addEventListener('click', event => {
        if (!!event.target.closest(menuDom.SELECTORS.ID)) {
            trackFlyoutDebounced.cancel();
            return;
        }
        trackFlyoutExitImmediately(menuDom.getMenuRoot());
    });

    initPanelEvents();
}

/**
 *
 *
 *
 *
 */
function showNextLevel(naviElem, navigationPath, menuState) {
    const levelContainer = naviElem.parentNode.querySelector(levelContainerDom.SELECTORS.BLOCK);
    if (!!levelContainer) {
        const naviPanel = findParentNodeByAttribute(naviElem, 'data-nav-swipe-status');

        navigationPath.push(naviPanel);
        naviPanelDom.animateDown(naviPanel);
        levelContainerDom.makeVisible(levelContainer);

        const level = navigationPath.length;
        /*                                                                  */

        if (level === 1) {
            trackFlyoutDown(naviElem, menuDom.getMenuRoot());
        } else {
            track(naviElem, "user-action/menu-navigate-down", commands => {
                    if (menuState?.shortcutMenu !== undefined) {
                        commands[0].dataContainer["nav_FirstLevelPosition"] = menuState.shortcutMenu.position;
                        commands[0].dataContainer["nav_PersonalisedCategories"] = menuState.shortcutMenu.isPersonalised;
                        commands[0].dataContainer["nav_GlobalNavigationFeature"] = "top_category";
                    } else {
                        commands[0].dataContainer["nav_GlobalNavigationFeature"] = "standard";
                    }
                    return commands;
                });
        }
    }
}

function navigateDown(naviElem, navigationPath, whenVisible = () => {
}) {
    if (hasLevelContainer(naviElem)) {
        showNextLevel(naviElem, navigationPath);
        whenVisible();
    } else {
        const downLink = naviElemDom.getDownLink(naviElem);
        if (!!downLink) {
            fetchHtml(downLink)
                .then(result => {
                    if (!!result && result.status < 400 && !!result.response) {
                        const levelContainer = result.response.querySelector(levelContainerDom.SELECTORS.BLOCK);
                        if (!!levelContainer) {
                            naviElemDom.appendLevelContainer(naviElem, levelContainer);
                            showNextLevel(naviElem, navigationPath);
                            window.o_util.hardcore.executeInlineScripts(levelContainer);
                            whenVisible();
                        }
                    }
                })
                .catch(defaultErrorHandler);
        }
    }
}

function initializeBrandSlider(parent) {
    const slider = parent.querySelector(`.nav_brand-slider`);
    if (slider) {
        initSlider(slider);
    }
}

function initPanelEvents() {
    const menuRoot = menuDom.getMenuRoot();

    if (!menuRoot) {
        return;
    }

    menuRoot.addEventListener('click', event => {
        const el = event.target;
        let {menuAction, menuActionEl} = naviElemDom.getMenuAction(el);

        /*                                                                             */
        /*                                                              */
        if (naviElemDom.isLevel1(el)) {
            return;
        }

        switch (menuAction) {
            case 'back':
                removeLevelContainerBackgrounds();
                addLevelContainerBackground(menuActionEl.closest(levelContainerDom.SELECTORS.LEVEL_2_MODIFIER));
                navigateUp(menuActionEl, navigationPath);
                event.stopPropagation();
                break;
            case 'close':
                onLeaveFirstLevel(menuRoot);
                trackFlyoutCloseImmediately(menuRoot);
                break;
            case 'down-with-image':
                navigateDown(menuActionEl, navigationPath, () => {
                    removeLevelContainerBackgrounds();
                    addLevelContainerBackground(menuActionEl.parentNode.querySelector(levelContainerDom.SELECTORS.BLOCK));
                    initializeBrandSlider(menuActionEl.parentNode);
                });
                break;
            default:
                navigateDown(el, navigationPath, () => {
                    removeLevelContainerBackgrounds();
                    addLevelContainerBackground(el.parentNode.querySelector(levelContainerDom.SELECTORS.BLOCK));
                    initializeBrandSlider(el.parentNode);
                });
        }
    });
}

function onOverFirstLevel(liElem) {
    const naviElem = liElem.querySelector(naviElemDom.SELECTORS.LEVEL_1_MODIFIER);
    if (!naviElem) {
        return;
    }

    function showFlyout() {
        /*                    */
        addLevelContainerBackground(naviElem.parentNode.querySelector(levelContainerDom.SELECTORS.BLOCK));
        showNextLevel(naviElem, navigationPath);
        naviElemDom.makeActive(naviElem);
    }

    if (hasLevelContainer(naviElem)) {
        showFlyout();
    } else {
        const url = naviElemDom.getDownLink(naviElem);
        naviElemDom.createTabElement(naviElem);

        fetchHtml(url)
            .then(result => {
                if (!!result && result.status < 400 && !!result.response) {
                    const levelContainer = result.response.querySelector(levelContainerDom.SELECTORS.BLOCK);

                    if (!!levelContainer) {
                        naviElemDom.appendLevelContainer(naviElem, levelContainer);
                        showFlyout();
                    }
                }
            })
            .catch(defaultErrorHandler);
    }
}

function addLevelContainerBackground(levelContainer) {
    if (!!levelContainer) {
        levelContainerDom.addBackground(levelContainer);
    }
}

function removeLevelContainerBackgrounds() {
    const navMenuRoot = menuDom.getMenuRoot();

    const backgrounds = Array.from(navMenuRoot.querySelectorAll(levelContainerDom.SELECTORS.BACKGROUND_MODIFIER));
    backgrounds.forEach(container => {
        levelContainerDom.removeBackground(container);
    });
}

function onLeaveFirstLevel(menuWrapper) {
    const naviElems = Array.from(menuWrapper.querySelectorAll(naviElemDom.SELECTORS.ACTIVE_MODIFER));
    naviElems.forEach(naviElem => {
        naviElemDom.makeInactive(naviElem);
        const naviPanel = naviElem.parentNode.querySelector(naviPanelDom.SELECTORS.DOWN_ANIMATION_MODIFIER);
        if (!!naviPanel) {
            naviPanelDom.animateUp(naviPanel);
        }
        const visibleLevelContainer = naviElem.parentNode.querySelector(levelContainerDom.SELECTORS.VISIBLE_MODIFIER);
        if (!!visibleLevelContainer) {
            levelContainerDom.makeInvisible(visibleLevelContainer);
        }
    });

    navigationPath = [];
    resetClasses();
    removeLevelContainerBackgrounds();
    trackFlyoutDebounced(FLYOUT_TRACKING_EVENTS.EXIT, menuWrapper);
}
