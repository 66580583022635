import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import {BrandSliderE3288} from "./brandSlider";
import {calculateTopOffset} from "./utils";
import {tabSwitchTracking} from "./tracking";

function registerElement(Elem) {
    customElements.get(Elem.customElementName) || customElements.define(Elem.customElementName, Elem);
}

export function registerMobile() {
    registerElement(MenuSheet);
    registerElement(MobileMenu);
    registerElement(MobileMenuButton);
    registerElement(MobileCluster);
    registerElement(MobileNavigationTile);
    registerElement(MobileNavigationElement);
    registerElement(MobileSheetBackButton);
    registerElement(MobileSheetTitle);
    registerElement(BrandSliderE3288);
}

export class MenuSheet extends HTMLElement {
    static customElementName = "nav-menu-sheet";
    static visible = "visible";

    static observedAttributes = ["loaded"];

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.attachShadow({mode: "open"});

        const sheet = new CSSStyleSheet();
        sheet.replaceSync(`
        :host {
            background-color: #f0f0f0;
            width: 100vw;
            height: calc(100vh - var(--nav-menu-top-offset));

            flex-direction: column;

            position: absolute;
            top: 0;
            left: 0;
        }
        :host(.submenu) {left: 100vw !important;}
        :host(.${MenuSheet.visible}) {display: flex !important;}
        `);
        this.shadowRoot.adoptedStyleSheets = [sheet];

        shadow.innerHTML = `<slot></slot>`;
    }

    ensureLoaded (showp) {
        if (!this.getAttribute("href") || this.loaded || !showp) {
            return Promise.resolve();
        } else {
            return fetch(this.getAttribute("href"))
                .then(response => response.text())
                .then(text => this.insertAdjacentHTML("beforeend", text))
                .then(() => {
                    this.loaded = true;
                    this.querySelectorAll('nav-tab').forEach(element =>
                        element.addEventListener("click", () => tabSwitchTracking(element)));
                });
        }
    }

    show () {
        return this.setVisibility(true);
    }
    hide () {
        return this.setVisibility(false);
    }
    toggleVisibility () {
        return this.setVisibility(!this.classList.contains(MenuSheet.visible));
    }
    setVisibility (value) {
        return this.ensureLoaded(value)
            .then(() => this.classList.toggle(MenuSheet.visible, value));
    }
}

export class MobileMenuButton extends HTMLElement {
    static customElementName = "nav-mobile-menu-button";

    constructor() {
        super();
        this.open = this.dataset.open || false;
        this.toggleMenu = () => {
            this.open = !this.open;

            if (this.open) {
                document.getElementsByTagName("body")[0].classList.add("nav__body--scrolling-disabled");
                this.classList.add("nav_menu-toggle-button--opened");

            } else {
                document.getElementsByTagName("body")[0].classList.remove("nav__body--scrolling-disabled");
                this.classList.remove("nav_menu-toggle-button--opened");
            }

            const controlledElement = document.getElementById(this.dataset.controls);
            const menuElement = document.getElementById(this.dataset.menuRoot);

            menuElement.style.setProperty('--nav-menu-top-offset', calculateTopOffset(menuElement));
            const allSheets = menuElement.querySelectorAll("nav-menu-sheet");

            allSheets.forEach(sheet => sheet.hide());
            menuElement.resetShift();
            controlledElement.toggleVisibility();

        };
    }

    connectedCallback() {
        if(!!this.dataset.controls) {
            this.addEventListener('click', () => {
                this.toggleMenu();

                if (this.open) {
                    track(this, "user-action/menu-open");
                } else {
                    track(this, "user-action/menu-close");
                }
            });
        }
        window.o_global.eventQBus.on('ftnav.global-navigation.open', () => {
            if (!this.open) {
                this.toggleMenu();
                track(this, "user-action/menu-open");
            }
        });
        window.o_global.eventQBus.on('ftnav.global-navigation.close', (opts) => {
            if (this.open) {
                this.toggleMenu();
                if (opts && opts.interaction === "button") {
                    track(this, "user-action/menu-close");
                } else {
                    track(this, "user-action/menu-exit");
                }
            }
        });
    }
}

export class MobileMenu extends HTMLElement {
    static customElementName = "nav-mobile-menu";
    static observedAttributes = ["shift"];

    constructor() {
        super();
        this.setAttribute("shift", 0);
    }

    connectedCallback() {
        let shadow = this.attachShadow({mode: "open"});

        const sheet = new CSSStyleSheet();
        sheet.replaceSync(`
        :host {
            display: block !important;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            left: 0;

            height: 0;
            overflow: visible;

            z-index: 11;
        }

        .inner-frame {
                transition: transform .3s ease, left .3s ease;
                position: absolute;
                left: 0;
        }
        `);
        shadow.adoptedStyleSheets = [sheet];

        /*                                                  */
        shadow.innerHTML = `
        <div class="inner-frame">
            <slot />
        </div>
        `;

        const innerSheet = this.querySelector("nav-menu-sheet");

        track(this, "user-action/menu-open"); /*      */
    }

    shiftRight (n) {
        this.setAttribute("shift", parseInt(this.getAttribute("shift")) + n);
    }

    resetShift () {
        this.setAttribute("shift", 0);
    }

    attributeChangedCallback (name, _oldValue, newValue) {
        if (name === "shift") {
            const element = this.shadowRoot.querySelector(".inner-frame");
            const dx = parseInt(this.getAttribute("shift")) * (-100);
            element.style.transform = `translateX(${dx}vw)`;
        }
    }
}

export class MobileCluster extends HTMLElement {
    static customElementName = "nav-mobile-cluster";
    constructor() {
        super();
    }
}

export class MobileNavigationTile extends HTMLElement {
    static customElementName = "nav-mobile-navigation-tile";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.attachShadow({mode: "open"});

        shadow.innerHTML = ` <slot/> `;

        this.addEventListener("click", (e) => {
            const sheet = document.getElementById(this.dataset.nextSheet);
            if (!! sheet) {
                const menu = e.target.closest("nav-mobile-menu");
                sheet.show().then(() => menu.shiftRight(1));
            }
            track(sheet, "user-action/menu-navigate-down");
        });
    }
}

export class MobileNavigationElement extends HTMLElement {
    static customElementName = "nav-mobile-navigation-element";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.attachShadow({mode: "open"});

        shadow.innerHTML = ` <slot/> `;

        this.addEventListener("click", (e) => {
            if (this.dataset.nextSheet) {
                const sheet = document.getElementById(this.dataset.nextSheet);
                track(sheet, "user-action/menu-navigate-down");
                const menu = e.target.closest("nav-mobile-menu");
                sheet.show().then(() => menu.shiftRight(1));
            } else {
                track(this, "user-action/visit-menu-link");
            }
        });
    }
}

export class MobileSheetTitle extends HTMLElement {
    static customElementName = "nav-mobile-sheet-title";

    constructor() {
        super();
    }

    connectedCallback() {
        let shadow = this.attachShadow({mode: "open"});

        shadow.innerHTML = ` <slot/> `;

        this.addEventListener("click", (e) => {
            track(this, "user-action/visit-menu-link");
        });
    }
}

export class MobileSheetBackButton extends HTMLElement {
    static customElementName = "nav-mobile-sheet-back-button";

    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', () => {
            const menu = this.closest(MobileMenu.customElementName);
            const sheet = this.closest(MenuSheet.customElementName);
            menu.shiftRight(-1);
            setTimeout(()=> sheet.hide(), 350);

            track(sheet, "user-action/menu-navigate-up");
        });
    }
}
