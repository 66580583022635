/**
 *
 */
export function isMobileBreakpoint() {
    let breakpoint = o_global.breakpoint.getCurrentBreakpoint();
    return (breakpoint === "s" || breakpoint === "m");
}

export function stringp (s) {
    return typeof s === "string" || s instanceof String;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function dom (hiccup) {
    if (stringp(hiccup)) {
        return document.createTextNode(hiccup);
    } else {
        if (!stringp(hiccup[0])) {
            console.error("first element must be a tag name", hiccup);
        }
        const d = document.createElement(hiccup.shift());
        if (hiccup[0] instanceof Object && !(hiccup[0] instanceof Array)) {
            for (const [k, v] of Object.entries(hiccup.shift())) {
                d.setAttribute(k, v);
            };
        }
        hiccup.filter(x => x != null).forEach(c => {
            d.appendChild(dom(c));
        });
        return d;
    }
}
