import {dom} from "./utils";

function defineCustomElement (name, clazz) {
    customElements.get(name) || customElements.define(name, clazz);
}

/**
 *
 *
 *
 *
 *
 *
 */
export class Tabs extends HTMLElement {
    static observedAttributes = ["selected-tab"];

    constructor () {
        super();
    }

    connectedCallback () {
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(`
:host {
    display: inline-block;}
div[role="tablist"] {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: stretch;
    text-align: center;} `);

        const shadowroot = this.attachShadow({mode: "open"});
        shadowroot.adoptedStyleSheets = [sheet];
        shadowroot.appendChild(dom(["div", {role: "tablist"},
                                    ["slot"]]));
        const defaultTab = this.getAttribute("selected-tab");
        window.o_global.eventQBus.on("ftnav.global-navigation.closed", /*                                          */
            () => this.setAttribute("selected-tab", defaultTab));
        this.addEventListener("click", (e) => {
            const tab = e.target.closest("nav-tab");
            if (tab) {
                this.switchTab(tab.getAttribute("name"));
            }
        });
    }

    switchTab (tabName) {
        this.setAttribute("selected-tab", tabName);
    }

    attributeChangedCallback (name, oldValue, newValue) {
        if (name === "selected-tab") {
            const selectedTab = this.querySelector('nav-tab[name="' + newValue + '"]');
            if(selectedTab) {
                this.querySelectorAll('nav-tab')
                    .forEach(tab => {
                        const sel = (tab.getAttribute("name") === newValue);
                        tab.setAttribute("aria-selected", sel);
                        tab.setAttribute("tabindex", (sel ? "0" : "-1"));
                    });
                this.parentElement.querySelectorAll('nav-tab-panel').forEach((element) => {
                    if(element.id === selectedTab.getAttribute("aria-controls")) element.removeAttribute("hidden");
                    else element.setAttribute("hidden", "hidden");
                });
            }

        }
    }
}
defineCustomElement("nav-tabs", Tabs);

/**
 *
 *
 *
 */
export class Tab extends HTMLElement {
    constructor () {
        super();
    }

    connectedCallback () {
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(`
:host {
    flex: 1 0 auto;
    border-bottom: 2px transparent solid;
    color: #777;
    cursor: pointer;}
:host([aria-selected="true"]) {
    color: #222;
    border-bottom-color: #222;} `);
        const shadowroot = this.attachShadow({mode: "open"});
        shadowroot.adoptedStyleSheets = [sheet];
        shadowroot.appendChild(dom(["div", {role: "tab"},
                                    this.getAttribute("title")]));
    }
}
defineCustomElement("nav-tab", Tab);

/**
 *
 *
 *
 */
export class TabPanel extends HTMLElement {
    constructor () {
        super();
    }

    connectedCallback () {
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(`
*[hidden] {
   display: none;}`);
        const shadowroot = this.attachShadow({mode: "open"});
        shadowroot.adoptedStyleSheets = [sheet];
        shadowroot.appendChild(dom(["div", {role: "tabpanel"},
                                    ["slot"]]));
    }
}
defineCustomElement("nav-tab-panel", TabPanel);
